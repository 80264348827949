import React from 'react'
import { graphql } from 'gatsby'
import moment from 'moment'

// Components
import Layout from '../components/Layout'
import Container from '../components/Layout/Container'
import Section from '../components/Layout/Section'
import PageTitle from '../components/Elements/PageTitle'
import Content, { HTMLContent } from '../components/Blog/Content'

export const ContentPageTemplate = ({
  title,
  date,
  content,
  contentComponent
}) => {
  const ContentPage = contentComponent || Content
  return (
    <article>
      <PageTitle title={title}>
        {moment(date).format('MMMM DD, YYYY')}
      </PageTitle>
      <Container>
        <Section>
          <ContentPage content={content} />
        </Section>
      </Container>
    </article>
  )
}

const ContentPage = ({ data }) => {
  const { markdownRemark: page } = data
  return (
    <Layout>
      <ContentPageTemplate
        title={page.frontmatter.title}
        date={page.frontmatter.date}
        content={page.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title,
        date
      }
    }
  }
`

export default ContentPage